/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import ShapedImage from '../components/shaped-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import Form from '../components/form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OGImage from '../images/tom-hirst-in-oslo.jpg';

const AboutMePage = ( props ) => {
    const {
        location,
    } = props;

    const SeoImageUrl = `https://www.tomhirst.com${OGImage}`;

    return (
        <Layout location={ location }>
            <SEO
                title="About Tom Hirst"
                description="I'm a web development consultant and freelancing mentor working with people around the world from my home office in Wakefield (near Leeds), UK."
                imageAlt="About Tom Hirst"
                imageUrl={ SeoImageUrl }
            />
            <Section narrow>
                <h1>About Tom Hirst</h1>
                <p className="lead">I&apos;m a web developer, business owner, author, creator and mentor working with people around the world from my home office in Wakefield (near Leeds), UK.
                </p>
                <ShapedImage
                    name="tom-hirst-in-oslo.jpg"
                    alt="Tom Hirst on-site with a client in Oslo"
                    pullRight
                    flipped
                />
                <p>
                    I&apos;ve grown up with the web.
                </p>
                <p>
                    Starting with a 56K modem and an after school
                    coding course, the internet and building websites grabbed
                    my attention.
                </p>
                <p>
                    My entrepreneurial streak was encouraged from a young age via my parents&apos; investment
                    in my first ever business; selling Pokémon cards at car boots sales.
                </p>
                <p>
                    Programming became my passion and working for myself became the goal.
                </p>
                <p>
                    I first discovered { <a href="https://wordpress.org/">WordPress</a> } in its infancy while studying
                    for my degree in multimedia and communication design at Sheffield Hallam University.
                </p>
                <p>
                    A blogging platform only at the time, I saw the potential of WordPress
                    and stuck with it.
                </p>
                <p>
                    WordPress has now become the go to content management system for
                    millions, powering { <a href="https://w3techs.com/">almost 41%</a> } of websites on
                    the internet.
                </p>
                <ShapedImage
                    name="th-and-sh-2.jpg"
                    alt="Tom Hirst with Steph Hirst"
                    pullLeft
                />
                <p>
                    Armed with my WordPress expertise and the desire to run my own company,
                    upon graduating from university I started my WordPress freelancing business aged 22.
                </p>
                <p>
                    As my web development career progressed, I picked up additional programming languages and honed my
                    business skills.
                </p>
                <p>
                    I followed the industry&apos;s movement towards JavaScript.
                </p>
                <p>
                    This knowledge has become imperative in WordPress development and the way modern websites and
                    applications are built.
                </p>
                <p>
                    Pairing WordPress with JavaScript (specifically <a href="https://nextjs.org/">Next.js</a>) to build super fast headless WordPress websites has proven to be
                    of high value to my collaborators.
                </p>
                <p>
                    Alongside running a successful { <a href="https://www.tomhirst.com/wordpress-consultant/">WordPress
                    consultancy</a> }, I gained digital marketing experience by way of the <Link to="/how-to-start-a-shopify-side-business-and-sell-it">clothing line I founded (and
                    then sold)</Link> with a friend.
                </p>
                <ShapedImage
                    name="th-and-bruce.jpg"
                    alt="Tom Hirst with Bruce"
                    pullRight
                    flipped
                />
                <p>A decade in business later, I&apos;ve partnered on digital projects for the likes of the NHS,
                    Facebook, Umbro, TGI Fridays and BMW, to name a few.
                </p>
                <p>
                    Being an effective remote worker, I&apos;ve delivered results for clients based in the USA,
                    Australia, throughout Europe and across the UK.
                </p>
                <p>
                    In addition to building things for the internet, I continue to pursue my interest in business and
                    marketing through my writing.
                </p>
                <p>
                    Having been a { <a href="https://www.tomhirst.com/">freelance WordPress developer</a> } for a long
                    time, I find it incredibly fulfilling to help others get ahead by sharing my experience.
                </p>
                <p>
                    I write regularly for { <a href="https://www.tomhirst.com/articles/">my blog</a> }, to { <a
                    href="https://www.tomhirst.com/join/">my mailing list</a> } subscribers and to { <a
                    href="https://twitter.com/tom_hirst/">my Twitter followers</a> } about { <a
                    href="https://www.tomhirst.com/category/freelancing/">the business of freelancing</a> }.
                </p>
                <p>
                    My { <a href="https://www.tomhirst.com/mentorship/">mentorship programme for freelancers</a> }, focused on teaching people how to
                    get paid what they&apos;re worth, work smarter and find balance, has helped 10s of self-employed professionals around the world get ahead since it&apos;s launch.
                </p>
                <p>
                    In April 2020, I released my first book on the topic named, { <a href="https://tomhir.st/10-steps/">10
                    Steps To Becoming A Better Freelancer</a> }.
                </p>
                <ShapedImage
                    name="tom-hirst-and-rh.jpg"
                    alt="Tom Hirst in Dubai"
                    pullLeft
                />
                <p>
                    In July 2020, I released my second book, { <a href="https://tomhir.st/pfp/">Pricing Freelance Projects</a> }.
                </p>
                <p>
                    In February 2021, I released <a href="https://tomhir.st/pwp/">The Personal Website Playbook</a>. A video course teaching all I know about creating a successful personal website.
                </p>
                <p>
                    To grow my WordPress consultancy business beyond myself, I launched <a href="https://runtheshow.agency">headless WordPress agency</a>, Run The Show in March 2021.
                </p>
                <p></p>
                <p>
                    Away from my desk I like to promote a balanced lifestyle. I&apos;m a gym goer, { <a
                    href="https://www.barnsleyfc.co.uk/">Barnsley Football Club</a> } regular, food lover and <a
                    href="https://www.instagram.com/tom_hirst/">keen traveller</a>.
                </p>
                <p>I live with my wife Steph and daughter Ruby in the { <a
                    href="https://en.wikipedia.org/wiki/Sandal_Magna">Sandal</a> } suburb of Wakefield where we enjoy
                    taking advantage of the Yorkshire countryside by walking our dog, Bruce.
                </p>
                <h2 id="interviews">Podcasts and Interviews with me</h2>
                <ul>
                    <li>
                        The Futur with Chris Do:&nbsp;
                        <a href="https://thefutur.com/podcast/how-to-price-your-work" target="_blank">
                            How to Price Your Work
                        </a>
                    </li>
                    <li>
                        Never Normal with Neville Andrew Mehra:&nbsp;
                        <a href="https://www.youtube.com/watch?v=KvpBC2K8drk" target="_blank">
                            How to earn six-figures without a job
                        </a>
                    </li>
                    <li>
                        The Personal Website Playbook with Tom Osman:&nbsp;
                        <a href="https://www.pscp.tv/w/1gqGvoRljLwJB" target="_blank">
                           Creating a personal website that converts
                        </a>
                    </li>
                    <li>
                        How Do You Know? with Andra Zaharia:&nbsp;
                        <a href="https://andrazaharia.com/short-term-changes-long-term-endgame-tom-hirst/" target="_blank">
                            Short-term changes for your long-term endgame
                        </a>
                    </li>
                    <li>
                       Get Doing Things with Craig Burgess:&nbsp;
                        <a href="https://www.pscp.tv/craigburgess/1ypKdglMEOvxW" target="_blank">
                            Growing your expertise and promoting yourself online
                        </a>
                    </li>
                    <li>
                        Live In The Feast with Jason Resnick:&nbsp;
                        <a href="https://rezzz.com/podcast/how-to-pivot-the-secret-to-great-seo-and-why-business-is-more-important-than-craft-with-tom-hirst" target="_blank">
                            How to pivot, the secret to great SEO, and why business is more important than craft
                        </a>
                    </li>
                    <li>
                        First Year Freelancing:&nbsp;
                        <a href="https://www.youtube.com/watch?v=ohWlnCvHy0E" target="_blank">
                            The story of my first year in freelancing
                        </a>
                    </li>
                    <li>
                        Talking with Francesco Ciulla:&nbsp;
                        <a href="https://www.youtube.com/watch?v=ZFZdY8grJWU" target="_blank">
                            How to freelance as a web developer for a long time
                        </a>
                    </li>
                    <li>
                        The Front End Podcast:&nbsp;
                        <a href="https://anchor.fm/the-front-end/episodes/Tom-Hirst-on-running-a-successful-freelancing-business-for-over-a-decade-ej4aho/a-a2t7v6u" target="_blank">
                            Running a successful freelancing business for over a decade
                        </a>
                    </li>
                    <li>
                        20MAX:&nbsp;
                        <a href="https://20max.co.uk/tom-hirst-freelance-developer/" target="_blank">
                            Winning your first client, maintaining work-life balance and the future of web development
                        </a>
                    </li>
                    <li>
                        WP Café:&nbsp;
                        <a href="https://www.youtube.com/watch?v=uUIjjDCUhI0" target="_blank">
                            Pricing WordPress products and services
                        </a>
                    </li>
                    <li>
                        Pressing Matters:&nbsp;
                        <a href="https://pressingmatters.fm/episodes/34-wordpress-freelancing-pricing-tom-hirst/" target="_blank">
                            WordPress Freelancing & Pricing with Tom Hirst
                        </a>
                    </li>
                    <li>
                        Whisky Wednesdays:&nbsp;
                        <a href="https://www.youtube.com/watch?v=k3lsSMByTPw" target="_blank">
                            Freelancing as a web developer
                        </a>
                    </li>
                    <li>
                        Addicted To Learning:&nbsp;
                        <a href="https://www.listennotes.com/podcasts/addicted-to-learning/7-tom-hirst-on-what-it-takes-2mrv8knsiue/" target="_blank">
                            On what it takes to win the freelancing game
                        </a>
                    </li>
                    <li>
                        The Freelance Dev:&nbsp;
                        <a href="https://soundcloud.com/thefreelancedev/3-pricing-positioning-confidence-with-tom-hirst" target="_blank">
                            Pricing, positioning & confidence with Tom Hirst
                        </a>
                    </li>
                </ul>
                <p><em>I&apos;m happy to appear on all relevant podcasts!</em></p>
                <h2>Connect with me</h2>
                <ul className="connect-links">
                    <li>
                        <a href="mailto:hello@tomhirst.com?subject=Email enquiry from tomhirst.com">
                            <FontAwesomeIcon icon="envelope" />
                            Email
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/tom_hirst/">
                            <FontAwesomeIcon icon={['fab', 'twitter']} />
                            Twitter
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/tom_hirst/">
                            <FontAwesomeIcon icon={['fab', 'instagram']} />
                            Instagram
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/tomhirst/">
                            <FontAwesomeIcon icon={['fab', 'linkedin']} />
                            LinkedIn
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/tomhirst">
                            <FontAwesomeIcon icon={['fab', 'github']} />
                            GitHub
                        </a>
                    </li>
                </ul>
                <h2 id="hire-me">I&apos;m available for hire</h2>
                <p>Fill out the form below to start a conversation with me:</p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I have a project to talk with you about."
                    pathname={ location && location.pathname }
                />
            </Section>
        </Layout>
    );
};

AboutMePage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

AboutMePage.defaultProps = {
    location: {},
};

export default AboutMePage;
